var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "character-detail" }, [
    _c("div", { staticClass: "head" }, [
      _c(
        "div",
        { staticClass: "image" },
        [
          _c("image-view", {
            attrs: {
              src: _vm.getCharacterKey,
              placeholder: "/img/placeholders/character.png",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "summary" }, [
        _c(
          "div",
          { staticClass: "main" },
          [
            _c("attribute", {
              attrs: { name: "役職", value: _vm.detail.role },
            }),
            _c("attribute", {
              attrs: { name: "名前", value: _vm.character.name },
            }),
            _c("attribute", {
              attrs: { name: "別名", value: _vm.detail.alias },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "main-2" }, [
          _c(
            "div",
            [
              _c("attribute", {
                attrs: { name: "年齢", value: _vm.detail.age },
              }),
              _c("attribute", {
                attrs: { name: "性別", value: _vm.detail.gender },
              }),
              _c("attribute", {
                attrs: { name: "誕生日", value: _vm.detail.birthdate },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("attribute", {
                attrs: { name: "血液型", value: _vm.detail.bloodType },
              }),
              _c("attribute", {
                attrs: { name: "身長", value: _vm.detail.height },
              }),
              _c("attribute", {
                attrs: { name: "体重", value: _vm.detail.weight },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "main-3" }, [
      _c(
        "div",
        [
          _c("attribute", { attrs: { name: "年齢", value: _vm.detail.age } }),
          _c("attribute", {
            attrs: { name: "性別", value: _vm.detail.gender },
          }),
          _c("attribute", {
            attrs: { name: "誕生日", value: _vm.detail.birthdate },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("attribute", {
            attrs: { name: "血液型", value: _vm.detail.bloodType },
          }),
          _c("attribute", {
            attrs: { name: "身長", value: _vm.detail.height },
          }),
          _c("attribute", {
            attrs: { name: "体重", value: _vm.detail.weight },
          }),
        ],
        1
      ),
    ]),
    _c("hr", { attrs: { width: "100%" } }),
    _c(
      "div",
      { staticClass: "main-4" },
      [
        _c("attribute", {
          attrs: {
            "hide-on-empty": "",
            "is-detail": "",
            name: "性格",
            value: _vm.detail.personality,
          },
        }),
        _c("attribute", {
          attrs: {
            "hide-on-empty": "",
            "is-detail": "",
            name: "個性",
            value: _vm.detail.individuality,
          },
        }),
        _c("attribute", {
          attrs: {
            "hide-on-empty": "",
            "is-detail": "",
            name: "特技",
            value: _vm.detail.skill,
          },
        }),
        _c("attribute", {
          attrs: {
            "hide-on-empty": "",
            "is-detail": "",
            name: "能力・スキル",
            value: _vm.detail.ability,
          },
        }),
        _c("attribute", {
          attrs: {
            "hide-on-empty": "",
            "is-detail": "",
            name: "見た目",
            value: _vm.detail.looks,
          },
        }),
        _c("attribute", {
          attrs: {
            "hide-on-empty": "",
            "is-detail": "",
            name: "生い立ち",
            value: _vm.detail.personalHistory,
          },
        }),
        _c("attribute", {
          attrs: {
            "hide-on-empty": "",
            "is-detail": "",
            name: "背景",
            value: _vm.detail.background,
          },
        }),
        _c("attribute", {
          attrs: {
            "hide-on-empty": "",
            "is-detail": "",
            name: "その他",
            value: _vm.detail.other,
          },
        }),
        _vm._l(_vm.additionalColumn, function (item) {
          return [
            _c("attribute", {
              key: item.name,
              attrs: { "is-detail": "", name: item.label, value: item.content },
            }),
          ]
        }),
      ],
      2
    ),
    _c(
      "div",
      [
        _c(
          "button-round",
          {
            staticClass: "character-detail-edit-button",
            attrs: {
              to: {
                name: "characterEdit",
                params: {
                  novelId: _vm.novelId,
                  characterId: _vm.character.characterId,
                  edit: "edit",
                },
              },
            },
          },
          [_vm._v(" 編集する ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }