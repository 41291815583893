var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.isOpen
      ? _c(
          "div",
          {
            ref: "popup",
            staticClass: "popup",
            style: _vm.location,
            on: { mouseenter: _vm.mouseEnter, mouseleave: _vm.mouseLeave },
          },
          [
            _c("div", { staticClass: "head" }, [_vm._v("校正：")]),
            _c("div", [_vm._v(_vm._s(_vm.message))]),
            _vm.fix
              ? [
                  _c("div", { staticClass: "head" }, [_vm._v("校正案：")]),
                  _c("div", [_vm._v(_vm._s(_vm.fixText))]),
                  _vm.fix
                    ? _c(
                        "button",
                        {
                          staticClass: "fix-button",
                          on: { click: _vm.handleFix },
                        },
                        [_vm._v("修正する")]
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }