var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "worldview-detail" }, [
    _c(
      "div",
      [
        _c("image-view", {
          staticClass: "image",
          attrs: {
            src: _vm.getWorldViewKey,
            placeholder: "/img/placeholders/worldview.png",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "detail-content" },
      [
        _c("div", { staticClass: "worldview-name" }, [
          _vm._v(_vm._s(_vm.worldView.name)),
        ]),
        _c("hr"),
        _c("attribute", {
          attrs: {
            "is-detail": "",
            "hide-on-empty": "",
            name: "時代",
            value: _vm.detail.period,
          },
        }),
        _c("attribute", {
          attrs: {
            "is-detail": "",
            "hide-on-empty": "",
            name: "法律",
            value: _vm.detail.law,
          },
        }),
        _c("attribute", {
          attrs: {
            "is-detail": "",
            "hide-on-empty": "",
            name: "政治",
            value: _vm.detail.politics,
          },
        }),
        _c("attribute", {
          attrs: {
            "is-detail": "",
            "hide-on-empty": "",
            name: "経済",
            value: _vm.detail.economy,
          },
        }),
        _c("attribute", {
          attrs: {
            "is-detail": "",
            "hide-on-empty": "",
            name: "軍事",
            value: _vm.detail.military,
          },
        }),
        _c("attribute", {
          attrs: {
            "is-detail": "",
            "hide-on-empty": "",
            name: "宗教",
            value: _vm.detail.religion,
          },
        }),
        _c("attribute", {
          attrs: {
            "is-detail": "",
            "hide-on-empty": "",
            name: "歴史",
            value: _vm.detail.history,
          },
        }),
        _c("attribute", {
          attrs: {
            "is-detail": "",
            "hide-on-empty": "",
            name: "言語",
            value: _vm.detail.language,
          },
        }),
        _c("attribute", {
          attrs: {
            "is-detail": "",
            "hide-on-empty": "",
            name: "自然環境",
            value: _vm.detail.environment,
          },
        }),
        _c("attribute", {
          attrs: {
            "is-detail": "",
            "hide-on-empty": "",
            name: "生活環境",
            value: _vm.detail.lifestyle,
          },
        }),
        _c("attribute", {
          attrs: {
            "is-detail": "",
            "hide-on-empty": "",
            name: "その他",
            value: _vm.detail.other,
          },
        }),
        _vm._l(_vm.additionalColumn, function (item) {
          return [
            _c("attribute", {
              key: item.name,
              attrs: { "is-detail": "", name: item.label, value: item.content },
            }),
          ]
        }),
      ],
      2
    ),
    _c(
      "div",
      [
        _c(
          "button-round",
          {
            staticClass: "worldview-detail-edit-button",
            attrs: {
              to: {
                name: "worldViewEdit",
                params: {
                  novelId: _vm.novelId,
                  worldViewId: _vm.worldView.worldViewId,
                  edit: "edit",
                },
              },
            },
          },
          [_vm._v(" 編集する ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }