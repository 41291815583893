var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onClickOutside,
          expression: "onClickOutside",
        },
      ],
      staticClass: "dropup-menu",
    },
    [
      _c(
        "div",
        {
          staticClass: "main",
          style: _vm.styleObject,
          on: { click: _vm.onClickMenu },
        },
        [
          _c("div", { staticClass: "content-wrapper" }, [
            _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
            _c("div", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.content)),
            ]),
          ]),
          _c("img", {
            class: { isOpen: _vm.isOpen },
            attrs: { src: require("@/assets/img/icon/expand_less.svg") },
          }),
        ]
      ),
      _vm.isOpen
        ? _c(
            "div",
            { staticClass: "menu" },
            [
              _vm._l(_vm.items, function (item, index) {
                return [
                  _c(
                    "div",
                    {
                      key: index,
                      staticClass: "item",
                      style: _vm.styleObject,
                      on: {
                        click: function ($event) {
                          return _vm.onClickItem(item)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.name))]
                  ),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }