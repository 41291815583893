var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
      },
    },
    [
      _c("line", {
        attrs: {
          x1: "20",
          x2: "20",
          y1: "10",
          y2: "22",
          fill: "none",
          stroke: _vm.isActive ? _vm.activeColor : _vm.defaultColor,
          "stroke-linecap": "round",
          "stroke-width": "2",
        },
      }),
      _c("line", {
        attrs: {
          x1: "12",
          x2: "12",
          y1: "2",
          y2: "22",
          fill: "none",
          stroke: _vm.isActive ? _vm.activeColor : _vm.defaultColor,
          "stroke-linecap": "round",
          "stroke-width": "2",
        },
      }),
      _c("line", {
        attrs: {
          x1: "4",
          x2: "4",
          y1: "2",
          y2: "22",
          fill: "none",
          stroke: _vm.isActive ? _vm.activeColor : _vm.defaultColor,
          "stroke-linecap": "round",
          "stroke-width": "2",
        },
      }),
      _c(
        "text",
        {
          attrs: {
            transform: "translate(16 8)",
            fill: _vm.isActive ? _vm.activeColor : _vm.defaultColor,
            "font-size": "10",
            "font-weight": "bold",
          },
        },
        [_vm._v(" A ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }