var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "message-box dialog-mask",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.onClickOutSide.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-box" }, [
        _vm._m(0),
        _c("div", { staticClass: "section dialog-body" }, [
          _c("h2", [
            _vm._v(
              "ルビ付き文字をマークダウンで入力します。表示はプレビューでご確認ください。"
            ),
          ]),
          _c("div", { staticClass: "setting-container" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.entryText,
                  expression: "entryText",
                },
              ],
              attrs: {
                type: "text",
                minlength: "1",
                maxlength: "10",
                placeholder: "親文字（ルビをふられる文字）",
              },
              domProps: { value: _vm.entryText },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.entryText = $event.target.value
                },
              },
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.rubyText,
                  expression: "rubyText",
                },
              ],
              attrs: {
                type: "text",
                minlength: "1",
                maxlength: "20",
                placeholder: "ルビ（ふりがな）",
              },
              domProps: { value: _vm.rubyText },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.rubyText = $event.target.value
                },
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "section button-area" }, [
          _c(
            "button",
            {
              staticClass: "button cancel",
              on: { click: _vm.onNegativeClick },
            },
            [_vm._v(_vm._s(_vm.negative))]
          ),
          _c(
            "button",
            {
              staticClass: "button primary",
              attrs: { disabled: !(_vm.entryText && _vm.rubyText) },
              on: { click: _vm.onPositiveClick },
            },
            [_vm._v(" " + _vm._s(_vm.positive) + " ")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "section dialog-header" }, [
      _c("h1", [_vm._v("ルビの設定")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }