var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "editor",
      class: { multiEditor: _vm.isMultiEditorPath },
      attrs: { "data-theme": _vm.theme },
    },
    [
      _vm.isMultiEditorPath
        ? _c("input", {
            staticClass: "title",
            attrs: {
              type: "text",
              placeholder: "タイトル",
              maxlength: "100",
              "data-theme": _vm.theme,
            },
            domProps: { value: _vm.title },
            on: {
              input: _vm.onChangeTitle,
              compositionstart: _vm.compositionStart,
              compositionend: _vm.compositionEnd,
            },
          })
        : _vm._e(),
      _c(
        "proofreading-editor",
        {
          attrs: { content: _vm.content, scrollTop: _vm.scrollTop },
          on: { fixed: _vm.onFixed },
        },
        [
          _c("textarea", {
            directives: [
              {
                name: "scroll",
                rawName: "v-scroll",
                value: _vm.handleScroll,
                expression: "handleScroll",
              },
            ],
            ref: "textarea",
            staticClass: "textarea",
            attrs: {
              id: "textarea",
              placeholder: "本文",
              "data-theme": _vm.theme,
            },
            domProps: { value: _vm.content },
            on: {
              input: _vm.onChangeContent,
              compositionstart: _vm.compositionStart,
              compositionend: _vm.compositionEnd,
              compositionupdate: _vm.compositionUpdate,
              keydown: _vm.keyDown,
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }