var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "message-box dialog-mask",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.onClickOutSide.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-box" }, [
        _vm._m(0),
        _c("div", { staticClass: "section dialog-body" }, [
          _c("h2", [_vm._v("執筆画面の色を変更することができます。")]),
          _c("div", { staticClass: "setting-container" }, [
            _c(
              "select",
              {
                domProps: { value: _vm.theme },
                on: {
                  change: function ($event) {
                    return _vm.onSelectColorTheme($event)
                  },
                },
              },
              [
                _c("option", { attrs: { value: "", disabled: "" } }, [
                  _vm._v("カラーを選択"),
                ]),
                _c("option", { attrs: { value: "default" } }, [
                  _vm._v("ノーマル"),
                ]),
                _c("option", { attrs: { value: "dark" } }, [
                  _vm._v("ダークモード"),
                ]),
                _c("option", { attrs: { value: "sepia" } }, [
                  _vm._v("セピアモード"),
                ]),
                _c("option", { attrs: { value: "blackboard" } }, [
                  _vm._v("黒板モード"),
                ]),
                _c("option", { attrs: { value: "bold" } }, [
                  _vm._v("太字モード"),
                ]),
              ]
            ),
          ]),
        ]),
        _c("div", { staticClass: "section button-area" }, [
          _c(
            "button",
            {
              staticClass: "button cancel",
              on: { click: _vm.onNegativeClick },
            },
            [_vm._v(_vm._s(_vm.negative))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "section dialog-header" }, [
      _c("h1", [_vm._v("カラーテーマ選択")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }