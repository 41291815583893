var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "message-box dialog-mask",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.onClickOutSide.apply(null, arguments)
        },
      },
    },
    [
      !_vm.previewVersionId
        ? _c("div", { staticClass: "dialog-box" }, [
            _c("div", { staticClass: "dialog-header" }, [
              _c("span", { staticClass: "empty" }),
              _c("span", { staticClass: "font-bold" }, [
                _vm._v("執筆履歴の確認と復元"),
              ]),
              _c(
                "span",
                { staticClass: "close", on: { click: _vm.onClickOutSide } },
                [_vm._v("× ウィンドウを閉じる")]
              ),
            ]),
            _c("div", { staticClass: "dialog-body" }, [
              _vm.isFree
                ? _c("div", { staticClass: "premium-guide" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v("執筆履歴からのデータ復元はプレミアム機能です"),
                    ]),
                    _c("div", { staticClass: "container" }, [
                      _vm._m(0),
                      _c(
                        "button",
                        {
                          staticClass: "button subscription",
                          on: { click: _vm.onClickSubscription },
                        },
                        [_vm._v("プランを確認する")]
                      ),
                    ]),
                  ])
                : _c("div", { staticClass: "description" }, [
                    _c("strong", [
                      _vm._v(
                        "過去の執筆履歴の確認と原稿データの復元が可能です。"
                      ),
                    ]),
                    _c("br"),
                    _vm._v(
                      " 執筆履歴は24時間以内であれば最大100件、24時間経過すると最新4件のみ保持して、それ以外は消去されます。 "
                    ),
                  ]),
              _c(
                "div",
                { staticClass: "version-list" },
                _vm._l(_vm.versionDates, function (date) {
                  return _c(
                    "div",
                    { key: date, staticClass: "list-item-container" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "version-date",
                          on: {
                            click: function ($event) {
                              return _vm.onToggleAccordion(date)
                            },
                          },
                        },
                        [
                          _c("chevron-down-icon", {
                            staticClass: "icon",
                            class: { opened: _vm.openedDate(date) },
                          }),
                          _c("div", { staticClass: "date" }, [
                            _vm._v(_vm._s(_vm.lastModifiedTime(date))),
                          ]),
                        ],
                        1
                      ),
                      _vm.openedDate(date)
                        ? _c(
                            "div",
                            _vm._l(
                              _vm.versionsByDate(date),
                              function (version) {
                                return _c(
                                  "div",
                                  {
                                    key: version.versionId,
                                    staticClass: "list-item",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "last-modified ellipsis" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              version.isLatest
                                                ? "現在"
                                                : _vm.lastModifiedDate(
                                                    version.lastModified
                                                  )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("div", [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "button default mr-1",
                                          attrs: { disabled: _vm.isFree },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onClickPreview(
                                                version.versionId
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" プレビュー ")]
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "button primary",
                                          attrs: { disabled: _vm.isFree },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onClickVersion(
                                                version.versionId
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 復元 ")]
                                      ),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
            ]),
          ])
        : _c("div", { staticClass: "dialog-box preview" }, [
            _c("div", { staticClass: "dialog-header" }, [
              _c(
                "span",
                { staticClass: "back", on: { click: _vm.onClickBack } },
                [_vm._v("＜ 執筆履歴に戻る")]
              ),
              _c("span", { staticClass: "font-bold" }, [
                _vm._v("執筆履歴の原稿差分プレビュー"),
              ]),
              _c(
                "span",
                { staticClass: "close", on: { click: _vm.onClickOutSide } },
                [_vm._v("× ウィンドウを閉じる")]
              ),
            ]),
            _c("div", { staticClass: "dialog-body preview" }, [
              _vm.existsDiff
                ? _c(
                    "div",
                    { staticClass: "diff-wrapper" },
                    [
                      _vm._m(1),
                      _c("code-diff", {
                        ref: "diff",
                        staticClass: "diff",
                        attrs: {
                          oldString: _vm.content,
                          newString: _vm.previewContent,
                          outputFormat: "side-by-side",
                          language: "text",
                          renderNothingWhenEmpty: true,
                        },
                        on: { "after-render": _vm.afterRenderDiff },
                      }),
                    ],
                    1
                  )
                : _c("div", { staticClass: "nothig-diff" }, [
                    _vm._v("変更はありません"),
                  ]),
            ]),
            _c("div", { staticClass: "dialog-footer" }, [
              _c(
                "button",
                {
                  staticClass: "button primary large",
                  attrs: { disabled: _vm.isFree },
                  on: {
                    click: function ($event) {
                      return _vm.onClickVersion(_vm.previewVersionId)
                    },
                  },
                },
                [_vm._v(" 復元を実行する ")]
              ),
            ]),
          ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "text" }, [
      _vm._v(
        " Nolaプレミアムにご登録いただくと、クラウドに保存されている原稿データを24時間以内であれば"
      ),
      _c("strong", [_vm._v("最大100件")]),
      _vm._v("、24時間経過したデータも"),
      _c("strong", [_vm._v("最新4件")]),
      _vm._v(
        "まで遡って復元することができます。執筆履歴の保存は無料プランの期間中も実施しており、Nolaプレミアムにご登録いただくとすぐにご利用が可能となっております。 "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "diff-header" }, [
      _c("div", { staticClass: "diff-old" }, [_vm._v("現在の原稿データ")]),
      _c("div", { staticClass: "diff-new" }, [
        _vm._v("選択中の執筆履歴データ"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }