var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "li",
    {
      class: {
        "list-item": true,
        "none-icon": _vm.icon === "none",
        active: _vm.isActive && !_vm.showHandle,
        "no-select": _vm.isEditMode,
        "manuscript-list-item-height": _vm.showProgress,
      },
      on: { click: _vm.click },
    },
    [
      _vm.icon !== "none"
        ? _c("image-view-round", {
            staticClass: "icon",
            attrs: { src: _vm.icon, placeholder: _vm.iconPlaceholder },
          })
        : _vm._e(),
      _c("div", { staticClass: "label-wrapper" }, [
        _c("div", { staticClass: "ellipsis" }, [
          _vm._v(" " + _vm._s(_vm.label) + " "),
        ]),
        !_vm.disabledFolder
          ? _c(
              "div",
              { staticClass: "folder-wrapper" },
              [
                [
                  _vm.folderName
                    ? _c("folder-outline-icon", {
                        staticClass: "flex flex-center",
                        attrs: { size: 12 },
                      })
                    : _c("folder-hidden-icon", {
                        staticClass: "flex flex-center",
                        attrs: { size: 12 },
                      }),
                ],
                _c("div", { staticClass: "folder ellipsis" }, [
                  _vm._v(_vm._s(_vm.folderName || "未設定")),
                ]),
              ],
              2
            )
          : _vm._e(),
      ]),
      _vm.showProgress &&
      !_vm.showHandle &&
      !_vm.isManuscriptDeleteMode &&
      !_vm.isManuscriptMoveFolderMode
        ? _c("div", { staticClass: "progress-chip-container" }, [
            _c(
              "span",
              {
                staticClass: "progress-chip",
                style: { minWidth: _vm.progressWidth },
              },
              [_vm._v(_vm._s(_vm.progress))]
            ),
          ])
        : _vm._e(),
      !_vm.showHandle && !_vm.showProgress
        ? _c("dots-horizontal-icon", {
            staticClass: "flex flex-center mr-5",
            attrs: { fillColor: _vm.iconColor },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.onClickMenu.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
      _vm.showHandle
        ? _c(
            "div",
            { staticClass: "handle" },
            [
              _c("equal-icon", {
                staticClass: "flex flex-center sort-icon",
                attrs: { fillColor: _vm.iconColor },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isShowMenu
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.closeMenu,
                  expression: "closeMenu",
                },
              ],
              staticClass: "menu",
            },
            [
              !_vm.disabledFolder
                ? _c(
                    "div",
                    {
                      staticClass: "menu-item",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.setFolder(_vm.item)
                        },
                      },
                    },
                    [_vm._v("フォルダ移動")]
                  )
                : _vm._e(),
              _vm.isCharactersPath
                ? _c(
                    "div",
                    {
                      staticClass: "menu-item",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.copyItem.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("複製")]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "menu-item",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.deleteItem.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("削除")]
              ),
            ]
          )
        : _vm._e(),
      _vm.isManuscriptDeleteMode
        ? _c("trash-can-outline-icon", {
            staticClass: "flex flex-center mr-5",
            attrs: { fillColor: "#c30407", size: 20 },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.deleteItem.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
      _vm.isManuscriptMoveFolderMode
        ? _c("div", { staticClass: "flex flex-center mr-5" }, [
            _c("img", {
              staticClass: "add-folder",
              attrs: { src: require("@/assets/img/icon/folder_add.png") },
              on: {
                click: function ($event) {
                  return _vm.setFolder(_vm.item)
                },
              },
            }),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }