var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("cupertino-alert-dialog", {
    attrs: { title: "フォルダ名の編集", close: _vm.onClickOutSide },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", { staticClass: "flex flex-column px-1 py-1" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.input,
                    expression: "input",
                  },
                ],
                staticClass: "flex flex-center py-1/2 px-1/2 mt-1 mb-1/2",
                style: { resize: "vertical" },
                attrs: { type: "text", placeholder: _vm.placeholder },
                domProps: { value: _vm.input },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.input = $event.target.value
                  },
                },
              }),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "flex flex-row flex-center flex-spacearound" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "w-full border-right-1 flex flex-center pointer py-1 hover left-bottom-radius-10",
                    on: { click: _vm.onClickCancel },
                  },
                  [_c("span", { staticClass: "bold" }, [_vm._v("キャンセル")])]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "w-full flex flex-center pointer py-1 hover right-bottom-radius-10",
                    class: { disabled: !_vm.input },
                    on: { click: _vm.onClickRename },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "bold color-blue",
                        class: { disabled: !_vm.input },
                      },
                      [_vm._v("変更する")]
                    ),
                  ]
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }