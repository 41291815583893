var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "premium-information" }, [
    _c("div", { staticClass: "premium-information-title" }, [
      _vm._v(_vm._s(_vm.contents.title)),
    ]),
    _c("div", { staticClass: "premium-information-description" }, [
      _vm._v(_vm._s(_vm.contents.content)),
    ]),
    _c("div", { staticClass: "premium-information-invite" }, [
      _c("span", [_vm._v("『" + _vm._s(_vm.contents.feature) + "』は")]),
      _c("br"),
      _c("span", { staticClass: "premium-color" }, [_vm._v("プレミアム会員")]),
      _c("span", [_vm._v("になると利用できます。")]),
    ]),
    _c("div", { staticClass: "premium-information-description" }, [
      _vm._v("プレミアム会員の詳細はマイページからご確認ください。"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }