var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      ref: "editorArea",
      staticClass: "proofreading-editor",
      on: { mousemove: _vm.onMouseMove },
    },
    [
      _c("div", { ref: "phantom", staticClass: "phantom" }, [
        _c("span", { attrs: { "data-s1": "", "data-theme": _vm.theme } }),
        _c("span", { attrs: { "data-s2": "", "data-theme": _vm.theme } }),
      ]),
      _vm.enableProofreading || _vm.enabledSearchReplace
        ? [
            _c("canvas", {
              ref: "canvas",
              staticClass: "canvas",
              attrs: { width: "800", height: "800" },
            }),
          ]
        : _vm._e(),
      _vm._t("default"),
      _c("proofreading-popup", {
        attrs: { show: _vm.showPopup, detail: _vm.popup },
        on: { fix: _vm.onFix },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }