var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.hideOnEmpty || _vm.value
    ? _c("div", [
        _c("span", { staticClass: "name", class: { lager: _vm.isDetail } }, [
          _vm._v(_vm._s(_vm.name || "-")),
        ]),
        !_vm.isDetail
          ? _c("span", { staticClass: "separate" }, [_vm._v(":")])
          : _c("br"),
        _c("span", { staticClass: "value" }, [
          _vm._v(_vm._s(_vm.value || "-")),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }