var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "list-root" }, [
    _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "edit-column" }, [
        _c("div", [_vm._v("フォルダ")]),
        _vm.isInsert
          ? _c(
              "div",
              { staticClass: "sorting", on: { click: _vm.completeSetFolder } },
              [_vm._v("移動する")]
            )
          : _c(
              "div",
              {
                staticClass: "sorting",
                on: {
                  click: function ($event) {
                    return _vm.switchEditMode()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.isEditMode ? "保存する" : "並び替え"))]
            ),
      ]),
    ]),
    _vm.items
      ? _c(
          "div",
          { staticClass: "list" },
          [
            !_vm.isInsert && !_vm.isEditMode
              ? _c("list-folder-item", {
                  attrs: {
                    label: "すべての世界観",
                    count: _vm.worldViewsCount,
                    onSelectCallback: () => _vm.selectFolder(),
                    disabledMenu: true,
                  },
                })
              : _vm._e(),
            _vm.isInsert
              ? _c("div", { staticClass: "folder-select-description" }, [
                  _vm._v("移動先のフォルダを選択してください"),
                ])
              : _vm._e(),
            _c("sortable-list", {
              staticClass: "list-container",
              attrs: {
                items: _vm.folders,
                keyAttr: "worldViewFolderId",
                disabled: !_vm.isEditMode,
                extra: _vm.isEditMode,
              },
              on: {
                "update:items": function ($event) {
                  _vm.folders = $event
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ item, extra }) {
                      return [
                        _c("list-folder-item", {
                          attrs: {
                            label: item.name,
                            count: item.worldViewKeys.length,
                            isSorting: extra,
                            isSelecting: _vm.isInsert,
                            onSelectCallback: () =>
                              _vm.selectFolder(item.worldViewFolderId),
                            isChecked: _vm.isSelectedFolder(
                              item.worldViewFolderId
                            ),
                            onCheckCallback: () =>
                              _vm.onToggleInsertFolder(item.worldViewFolderId),
                            onRenameCallback: () =>
                              _vm.renameWorldViewFolder(item.worldViewFolderId),
                            onDeleteCallback: () =>
                              _vm.deleteWorldViewFolder(item.worldViewFolderId),
                          },
                        }),
                      ]
                    },
                  },
                ],
                null,
                false,
                2049919437
              ),
            }),
          ],
          1
        )
      : _vm._e(),
    _c(
      "button",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isEditMode && !_vm.isInsert,
            expression: "!isEditMode && !isInsert",
          },
        ],
        staticClass: "create",
        on: { click: _vm.createWorldViewFolder },
      },
      [_vm._v("＋ 新規作成する")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }