var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "message-box dialog-mask",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.onClickOutSide.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-box" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "section dialog-body" },
          [
            _c("h2", [
              _vm._v(
                "縦書きプレビューで表示する文字数のレイアウトや原稿用紙換算での文字数を設定できます。"
              ),
            ]),
            _c("div", { staticClass: "setting-container" }, [
              _c("div", [_vm._v("縦の文字数")]),
              _c("div", { staticClass: "inline" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.validatedWordCountOnVertical,
                      expression: "validatedWordCountOnVertical",
                      modifiers: { number: true },
                    },
                  ],
                  attrs: {
                    type: "number",
                    min: "16",
                    max: "50",
                    disabled: !_vm.isChangeable,
                  },
                  domProps: { value: _vm.validatedWordCountOnVertical },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.validatedWordCountOnVertical = _vm._n(
                        $event.target.value
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _c("span", [_vm._v("字")]),
                _c("span", { staticClass: "font-small" }, [
                  _vm._v("（ 16 ~ 50字 ）"),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "setting-container" }, [
              _c("div", [_vm._v("1ページあたりの行数")]),
              _c("div", { staticClass: "inline" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.validatedLineCountPerPage,
                      expression: "validatedLineCountPerPage",
                      modifiers: { number: true },
                    },
                  ],
                  attrs: {
                    type: "number",
                    min: "16",
                    max: "40",
                    disabled: !_vm.isChangeable,
                  },
                  domProps: { value: _vm.validatedLineCountPerPage },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.validatedLineCountPerPage = _vm._n(
                        $event.target.value
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _c("span", [_vm._v("行")]),
                _c("span", { staticClass: "font-small" }, [
                  _vm._v("（ 16 ~ 40行 ）"),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "setting-container" }, [
              _c("div", [_vm._v("文字サイズの倍率")]),
              _c("div", { staticClass: "inline" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.validatedFontSizeRate,
                      expression: "validatedFontSizeRate",
                      modifiers: { number: true },
                    },
                  ],
                  attrs: {
                    type: "number",
                    min: "0.8",
                    max: "1.5",
                    step: "0.1",
                    disabled: !_vm.isChangeable,
                  },
                  domProps: { value: _vm.validatedFontSizeRate },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.validatedFontSizeRate = _vm._n($event.target.value)
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _c("span", [_vm._v("倍")]),
                _c("span", { staticClass: "font-small" }, [
                  _vm._v("（ 0.8 ~ 1.5倍 ）"),
                ]),
              ]),
            ]),
            !_vm.isChangeable ? _c("premium-information") : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "section button-area" }, [
          _c(
            "button",
            {
              staticClass: "button cancel",
              on: { click: _vm.onNegativeClick },
            },
            [_vm._v(_vm._s(_vm.negative))]
          ),
          _c(
            "button",
            {
              staticClass: "button primary",
              attrs: { disabled: !_vm.isChangeable },
              on: { click: _vm.onPositiveClick },
            },
            [_vm._v(" " + _vm._s(_vm.positive) + " ")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "section dialog-header" }, [
      _c("h1", [_vm._v("原稿の設定")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }