var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "scroll",
          rawName: "v-scroll",
          value: _vm.handleScroll,
          expression: "handleScroll",
        },
      ],
      ref: "previewWrapper",
      staticClass: "manuscript-preview-wrapper",
    },
    [
      _c(
        "div",
        {
          staticClass: "manuscript-preview",
          style: _vm.styles,
          attrs: { "data-theme": _vm.theme },
        },
        [
          _vm._l(_vm.manuscriptList, function (manuscript) {
            return [
              _c("div", { key: manuscript.key }, [
                _c("h1", [
                  _vm._v(_vm._s(manuscript.title || "タイトル未設定")),
                ]),
                _c("div", {
                  staticClass: "content",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$sanitize(_vm.htmlContent(manuscript.content))
                    ),
                  },
                }),
              ]),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }