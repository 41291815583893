var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "li",
    { staticClass: "list-item", on: { click: _vm.click } },
    [
      _c("folder-outline-icon", { staticClass: "flex flex-center mx-5" }),
      _c("div", { staticClass: "label ellipsis" }, [_vm._v(_vm._s(_vm.label))]),
      _c(
        "div",
        { staticClass: "handle" },
        [
          _c("div", { staticClass: "count" }, [_vm._v(_vm._s(_vm.count))]),
          _vm.isSorting
            ? _c("equal-icon", {
                staticClass: "flex flex-center mx-5 sort-icon",
                attrs: { fillColor: _vm.iconColor },
              })
            : _vm.isSelecting
            ? _c("checkbox-icon", {
                staticClass: "flex flex-center mx-5",
                attrs: { isChecked: _vm.isChecked, fill: _vm.checkBoxColor },
              })
            : _c("dots-horizontal-icon", {
                staticClass: "flex flex-center mx-5",
                class: { hidden: _vm.disabledMenu },
                attrs: { fillColor: _vm.iconColor },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.onClickMenu.apply(null, arguments)
                  },
                },
              }),
        ],
        1
      ),
      _vm.isShowMenu
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.closeMenu,
                  expression: "closeMenu",
                },
              ],
              staticClass: "menu",
            },
            [
              _c(
                "div",
                {
                  staticClass: "menu-item",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.rename.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("フォルダ名編集")]
              ),
              _c(
                "div",
                {
                  staticClass: "menu-item",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.deleteItem.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("削除")]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }